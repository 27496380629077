<template>
    <div class="home">
      <v-layout row wrap class="d-flex justify-center">
        <v-flex xs12 md12 lg12 class="mb-5">
          <h1 class="f-alfa primary--text"><router-link to="/">Pro Track</router-link> / Projects</h1>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <v-layout row wrap class="d-flex justify-space-between align-center">
              <v-flex xs8 md6 lg4>
                  
              </v-flex>
              <v-flex xs4 md4 lg4 class="d-flex justify-end">
                  <v-btn class="white--text primary" @click="addProject" rounded><v-icon class="mr-2">mdi-plus</v-icon>Add Project</v-btn>
              </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left uppercase grey--text">#</th>
                  <th class="text-left uppercase grey--text">Project</th>
                  <!-- <th class="text-left uppercase grey--text">Date</th> -->
                  <th class="text-left uppercase grey--text">Actions</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in projects" :key="item._id">
                  <td>{{ index+1 }}</td>
                  <td class="uppercase fw-bold">{{ item.name }}</td>
                  <!-- <td><span  class="accent--text">{{ item.updated }}</span></td> -->
                  <td><v-btn  small class="white--text" @click="viewProject(item._id)"><v-icon color="primary">mdi-magnify</v-icon></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import Axios from "axios";
  import { BASE_URL } from "@/config";
  export default {
    data() {
      return {
        projects: [],
      };
    },
    mounted() {
      this.fetchProjects();
    },
   
    methods: {
      async fetchProjects() {
        let url = BASE_URL + "/project";
        let { data } = await Axios.get(url);
        this.projects = data;
      },
      viewProject(id) {
        this.$router.push({ name: "ViewProject", params: { id: id } });
      },
      addProject(){
        this.$router.push('/add-project')
      }
    },
  };
  </script>
  
  
  